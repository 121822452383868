import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import localeDe from '@angular/common/locales/de'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppTranslateModule } from './app-translate.module';
import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { AppTokenModule } from './app-token.module';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    AppTokenModule,
    AppTranslateModule,
    HttpClientModule,
    MatButtonModule,
  ],
  providers: [
    DatePipe,
    { provide: LOCALE_ID, useValue: "de" }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
